import {
  checkAccessToModule,
  checkSingleChoicePermission,
  hasPermission,
  hasPermissionInApp,
} from 'app/shared/utils';
import { AI_SEARCH_CATEGORY, AI_SEARCH_HEADER_CLICK, User } from 'app/state/users';
import { Client, NEW_CLIENT_ROUTE } from 'app/state/clients';

import { constants } from '@config/constants';
import {
  checkAccessToClientEdit,
  checkAccessToMaximize,
  checkCampaignsListAccess,
  checkExpressScenariosAccess,
  hideChildlessItems,
  NavigationConfigFunctions,
  NavigationSection,
  UserSectionNavigationName,
} from '@config';
import { environment } from '@environment';
import { TrackerActionType } from '@mads/wm-ng-components';

const { APPS_IDS, USER_PERMISSION_KEYS } = constants;

export const navigation: NavigationConfigFunctions = {
  dashboard: (user: User): NavigationSection => [
    {
      id: 'WM_HOME.HEADER.YOU',
      title: 'WM_HOME.HEADER.YOU',
      path: '/',
      hidden: checkAccessToModule(user, constants.MODULE_KEYS.YOU_V2),
    },
    {
      id: 'WM_HOME.HEADER.YOU',
      title: 'WM_HOME.HEADER.YOU',
      path: '/you/dashboard',
      hidden: !checkAccessToModule(user, constants.MODULE_KEYS.YOU_V2),
    },
    {
      id: 'WM_HOME.HEADER.AI_SEARCH',
      title: 'WM_HOME.HEADER.AI_SEARCH',
      path: environment.superSearchUrl,
      hidden: !checkSingleChoicePermission(
        user,
        constants.USER_PERMISSION_KEYS.CAN_ACCESS,
        environment.appsIds.superSearchAppId
      ),
      isNew: false,
      external: true,
      trackerPayload: {
        category: AI_SEARCH_CATEGORY,
        type: TrackerActionType.click,
        details: AI_SEARCH_HEADER_CLICK,
      },
    },
    {
      id: 'WM_HOME.HEADER.AI_CENTER',
      title: 'WM_HOME.HEADER.AI_CENTER',
      path: '/ai-center',
      hidden: !checkSingleChoicePermission(
        user,
        constants.USER_PERMISSION_KEYS.CAN_ACCESS,
        environment.appsIds.aiCenterAppId
      ),
    },
    {
      id: 'WM_HOME.HEADER.APPS',
      title: 'WM_HOME.HEADER.APPS',
      path: '/apps/list',
      disabled: !checkAccessToModule(user, constants.MODULE_KEYS.APPS),
    },
    {
      id: 'WM_HOME.HEADER.PROJECT_BUILDER',
      title: 'WM_HOME.HEADER.PROJECT_BUILDER',
      path: '/project-builder',
      hidden: !checkAccessToModule(user, constants.MODULE_KEYS.GROWTH_PATH),
      reloadOnClick: true,
    },
  ],
  main: (countryCode: string, user: User): NavigationSection =>
    [
      {
        id: 'Unlock',
        title: 'Unlock',
        description:
          'CX Intelligence. Explore the whole CX Journey to identify opportunities for growth.',
        nav: [
          {
            id: 'Uncover CX insights',
            title: 'Uncover CX insights',
            nav: [
              {
                id: 'Access purchase journey studies',
                title: 'Access purchase journey studies',
                description: 'momentum platform',
                toolName: 'Momentum',
                path: 'https://wmplanningtools.com/momentum/about?theme=wavemaker',
                external: true,
              },
              {
                id: 'Compare purchase journeys',
                title: 'Compare purchase journeys',
                description: 'momentum benchmarks',
                toolName: 'Momentum Benchmarks',
                path: 'https://wmplanningtools.com/benchmarks/about?theme=wavemaker',
                external: true,
              },
              {
                id: 'CX Journey',
                title: 'Discover live journey insights',
                path: '/consumer-experience-insights/intro',
                description: 'CX Journey',
                toolName: 'CX Journey',
                isNew: false,
                hidden: !checkSingleChoicePermission(
                  user,
                  constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  environment.appsIds.consumerExperienceInsightsAppId
                ),
              },
              {
                id: 'Analyze brand equity',
                title: 'Analyze brand equity',
                description: 'bav',
                toolName: 'BAV',
                path: 'https://wppbav.com/',
                external: true,
              },
            ],
          },
          {
            id: 'Conduct CX audits',
            title: 'Conduct CX audits',
            nav: [
              {
                id: 'Scorecards summary',
                title: 'Scorecards summary',
                toolName: 'Scorecards: summary',
                path: '/scorecards/summary',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Applied Innovation',
                title: 'Applied Innovation',
                toolName: 'Scorecards: Applied Innovation',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000010/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Audiences',
                title: 'Audiences',
                toolName: 'Scorecards: Audiences',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000005/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'B2B',
                title: 'B2B',
                toolName: 'Scorecards: B2B',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000011/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Content',
                title: 'Content',
                toolName: 'Scorecards: Content',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000004/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Ecommerce',
                title: 'Ecommerce',
                toolName: 'Scorecards: Ecommerce',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000006/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Paid search',
                title: 'Paid search',
                toolName: 'Scorecards: Paid search',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000001/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Programmatic',
                title: 'Programmatic',
                toolName: 'Scorecards: Programmatic',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000007/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Site and apps',
                title: 'Site and apps',
                toolName: 'Scorecards: Site and apps',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000003/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Social',
                title: 'Social',
                toolName: 'Scorecards: Social',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000002/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Technology',
                title: 'Technology',
                toolName: 'Scorecards: Technology',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000009/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'TikTok',
                title: 'TikTok',
                toolName: 'Scorecards: TikTok',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000012/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
                isNew: true,
              },
              {
                id: 'Video',
                title: 'Video',
                toolName: 'Scorecards: Video',
                path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000008/intro',
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.SCORECARDS),
              },
              {
                id: 'Create growth opportunities roadmap',
                title: 'Create growth opportunities roadmap',
                toolName: 'Opportunities',
                path: `/growth-opportunities/opportunities`,
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.OPPORTUNITIES),
              },
            ],
          },
        ],
      },
      {
        id: 'Maximize',
        title: 'Maximize',
        description: 'Everyday excellence. Create the most effective channel plan.',
        nav: [
          {
            id: 'Provoke the brief parent',
            title: 'Provoke the brief',
            nav: [
              {
                id: 'Provoke the brief link',
                title: 'Provoke the brief',
                toolName: 'Provoke the brief',
                path: '/provoke-brief',
                hidden: !checkSingleChoicePermission(
                  user,
                  constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  environment.appsIds.provokeBriefAppId
                ),
              },
            ],
          },
          {
            id: 'Quantify the Source of growth',
            title: 'Quantify the source of growth',
            hidden: !checkAccessToModule(user, constants.MODULE_KEYS.FOUR_MORES),
            nav: [
              {
                id: 'Source of growth',
                title: 'Source of growth',
                toolName: 'Source of growth',
                path: '/sog/intro',
              },
            ],
          },
          {
            id: 'Investigate the audiences',
            title: 'Investigate the audience',
            nav: [
              {
                id: 'Create audiences',
                title: 'Create audiences',
                description: 'architect audiences',
                toolName: 'Architect Audiences',
                path: `/audiences/map`,
                disabled:
                  !checkAccessToMaximize(countryCode) ||
                  !checkAccessToModule(user, constants.MODULE_KEYS.MAXIMIZE),
              },
              {
                id: 'galaxy-in-maximize',
                title: 'Explore Amazon audiences',
                description: 'audience galaxy',
                toolName: 'Audience Galaxy',
                path: `/audience-galaxy/start`,
                disabled: !checkSingleChoicePermission(
                  user,
                  constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  environment.appsIds.audienceGalaxyId
                ),
              },
              {
                id: 'LIVE Audiences',
                title: 'Explore Partner audiences',
                description: 'LIVE Audiences',
                toolName: 'Live audiences',
                path: '/live-audiences',
                hidden: !checkSingleChoicePermission(
                  user,
                  constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  environment.appsIds.cxiAudiencesAppId
                ),
              },
            ],
          },
          {
            id: 'Build the plan',
            title: 'Build the plan',
            nav: [
              {
                id: 'Configure campaign touchpoints',
                title: 'Configure campaign touchpoints',
                description: 'architect touchpoints',
                toolName: 'Architect Touchpoints',
                path: `/touchpoints-ui`,
                isNew: true,
                hidden: !checkSingleChoicePermission(
                  user,
                  constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  environment.appsIds.touchpointsUIAppId
                ),
                disabled:
                  !checkAccessToMaximize(countryCode) ||
                  !checkAccessToModule(user, constants.MODULE_KEYS.MAXIMIZE),
              },
              {
                id: 'Create Influencer touchpoints',
                title: 'Create Influencer touchpoints',
                description: 'Influencers for Architect',
                toolName: 'Influencers for Architect',
                path: '/influencer-viewer/intro',
                hidden: !checkSingleChoicePermission(
                  user,
                  constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  environment.appsIds.influencerViewerAppId
                ),
                isNew: true,
              },
              {
                id: 'Explore campaigns',
                title: 'Create campaigns',
                description: 'architect campaigns',
                toolName: 'New Redesigned Architect Campaigns',
                path: `/campaigns/list`,
                isNew: true,
                hidden: !checkCampaignsListAccess(user),
                disabled:
                  !checkAccessToMaximize(countryCode) ||
                  !checkAccessToModule(user, constants.MODULE_KEYS.MAXIMIZE),
              },
              {
                id: 'Express scenarios',
                title: 'Express scenarios',
                description: 'architect express scenarios',
                toolName: 'Architect Campaigns',
                path: `/express-scenarios`,
                hidden: !checkExpressScenariosAccess(user),
                disabled:
                  !checkAccessToMaximize(countryCode) ||
                  !checkAccessToModule(user, constants.MODULE_KEYS.MAXIMIZE),
              },
            ],
          },
          {
            id: 'Activate the plan',
            title: 'Activate the plan',
            nav: [
              {
                id: 'Audience Translator',
                title: 'Translate planning audiences to activation platforms',
                description: 'audience translator',
                toolName: 'Audience Translator',
                path: '/xlator',
                disabled: !checkSingleChoicePermission(
                  user,
                  constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  environment.appsIds.audienceTranslatorAppId
                ),
                hidden: !checkSingleChoicePermission(
                  user,
                  constants.FEATURE_FLAG_KEYS.FF_CAN_VIEW,
                  environment.appsIds.audienceTranslatorAppId
                ),
              },
            ],
          },
        ],
      },
      {
        id: 'Transform',
        title: 'Transform',
        description: 'Future fit. Take a step back and consult on strategic opportunities.',
        disabled: !checkAccessToModule(user, constants.MODULE_KEYS.TRANSFORM),
        nav: [
          {
            id: 'Develop the communications plan (toolkits)',
            title: 'Develop the communications plan (toolkits)',
            nav: [
              {
                id: 'Ideas',
                title: 'Ideas',
                toolName: 'Toolkit: Ideas',
                path: 'https://insidemedia.sharepoint.com/sites/WMGTransform/SitePages/Ideas.aspx',
                external: true,
                iconPath: 'assets/icons/tool-menu-item.svg',
              },
              {
                id: 'Insights',
                title: 'Insights',
                toolName: 'Toolkit: Insights',
                path: 'https://insidemedia.sharepoint.com/sites/WMGTransform/SitePages/Insights.aspx',
                external: true,
                iconPath: 'assets/icons/tool-menu-item.svg',
              },
              {
                id: 'Precision',
                title: 'Precision',
                toolName: 'Toolkit: Precision',
                path: 'https://insidemedia.sharepoint.com/sites/WMGTransform/SitePages/Precision.aspx',
                external: true,
                iconPath: 'assets/icons/tool-menu-item.svg',
              },
              {
                id: 'Strategy',
                title: 'Strategy',
                toolName: 'Toolkit: Strategy',
                path: 'https://insidemedia.sharepoint.com/sites/WMGTransform/SitePages/Strategy.aspx',
                external: true,
                iconPath: 'assets/icons/tool-menu-item.svg',
              },
            ],
          },
          {
            id: 'Leverage data and tech',
            title: 'Leverage data and tech',
            nav: [
              {
                id: 'Build a data strategy',
                title: 'Build a data strategy',
                description: 'data strategy',
                toolName: 'Data Strategy',
                path: `/data-strategy-deck/intro`,
                hidden: !checkAccessToModule(user, constants.MODULE_KEYS.DATA_STRATEGY_DECK),
              },
              {
                id: 'Explore geo-based data sets',
                title: 'Explore geo-based data sets',
                description: 'geograph',
                toolName: 'Geograph',
                path: '/geograph',
                hidden: !checkAccessToModule(user, constants.MODULE_KEYS.GEOGRAPH),
              },
            ],
          },
          {
            id: 'Explore commerce',
            title: 'Explore commerce',
            nav: [
              {
                id: 'Optimize Amazon search budgets',
                title: 'Optimize Amazon search budgets',
                description: 'retail compass',
                toolName: 'Retail Compass',
                path: `/retail-compass/dashboard`,
                disabled: !checkAccessToModule(user, constants.MODULE_KEYS.RETAIL_COMPASS),
              },
              // {
              //   id: 'galaxy-in-transform',
              //   title: 'Explore Amazon audiences',
              //   description: 'audience galaxy',
              //   toolName: 'Audience Galaxy',
              //   path: `/audience-galaxy/start`,
              //   disabled: !checkSingleChoicePermission(
              //     user,
              //     constants.USER_PERMISSION_KEYS.CAN_ACCESS,
              //     environment.appsIds.audienceGalaxyId
              //   ),
              // },
            ],
          },
          {
            id: 'Explore content',
            title: 'Explore content',
            nav: [
              {
                id: 'Find content creators',
                title: 'Find content creators',
                description: 'INCA',
                toolName: 'INCA',
                path: 'https://www.youtube.com/watch?v=y6120QOlsfU',
                external: true,
                hidden: true, // remove line when business says "show INCA"
              },
            ],
          },
          {
            id: 'Run simulations',
            title: 'Run simulations',
            nav: [
              {
                id: 'Request MMM-on-demand',
                title: 'Request MMM-on-demand',
                description: 'MMM on demand',
                toolName: 'MMM on demand',
                path: '/media-marketing-mix',
                disabled: !checkSingleChoicePermission(
                  user,
                  constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  environment.appsIds.mediaMarketingMixAppId
                ),
                hidden: !checkSingleChoicePermission(
                  user,
                  constants.FEATURE_FLAG_KEYS.FF_CAN_VIEW,
                  environment.appsIds.mediaMarketingMixAppId
                ),
              },
              {
                id: 'Run strategic sales simulations',
                title: 'Run strategic sales simulations',
                description: 'Long-term Growth Simulator',
                toolName: 'Long-term Growth Simulator',
                path: '/long-term-growth-simulator',
                hidden: !checkSingleChoicePermission(
                  user,
                  constants.USER_PERMISSION_KEYS.CAN_ACCESS,
                  environment.appsIds.velocityAppId
                ),
                isNew: true,
              },
            ],
          },
          // --------------- those where not included on new design:
          // {
          //   title: 'zapomnieńcy',
          //     {
          //       title: 'WM_HOME.NAVIGATION_MAIN.BUDGET',
          //       path: '/budget/settings',
          //       disabled: !checkAccessToModule(user, constants.MODULE_KEYS.BUDGET),
          //       hidden: true,
          //     },
          //     {
          //       title: 'WM_HOME.NAVIGATION_MAIN.LIVE_JOURNEY_TOOLKIT',
          //       path: 'https://insidemedia.sharepoint.com/:p:/r/sites/wmgostraining/_layouts/15/Doc.aspx?sourcedoc=%7BAACB8531-2549-4C71-883C-B018287BEFFF%7D&%3Bfile=Live+Journey+Toolkit_Final+061021pptx.pptx&%3Baction=edit&%3Bmobileredirect=true&%3BDefaultItemOpen=1&isSPOFile=1',
          //       external: true,
          //     },
          //   ],
          // },
        ],
      },
    ].map(hideChildlessItems),
  avatarNavigation: (user: User): NavigationSection => [
    {
      id: 'WM_HOME.HEADER.ADMINISTRATION',
      title: 'WM_HOME.HEADER.ADMINISTRATION',
      nav: [
        {
          id: 'WM_HOME.HEADER.OS_PAY',
          title: 'WM_HOME.HEADER.OS_PAY',
          path: '/os-pay',
          disabled: false,
          hidden: !checkSingleChoicePermission(
            user,
            constants.USER_PERMISSION_KEYS.CAN_UI_ACCESS,
            environment.appsIds.osPayId
          ),
        },
        {
          hidden: !hasPermissionInApp(user, APPS_IDS.WM_APPS, [
            USER_PERMISSION_KEYS.CAN_MANAGE_MARKET_APPS,
            USER_PERMISSION_KEYS.CAN_MANAGE_GLOBAL_APPS,
            USER_PERMISSION_KEYS.CAN_MANAGE_ALL_APPS,
          ]),
          id: 'WM_HOME.HEADER.APP_SETTING',
          title: 'WM_HOME.HEADER.APP_SETTING',
          path: '/apps/admin-panel',
        },
        {
          hidden: !hasPermission(user, USER_PERMISSION_KEYS.CAN_MANAGE_MARKET),
          id: 'WM_HOME.HEADER.MARKET',
          title: 'WM_HOME.HEADER.MARKET',
          path: '/market-admin/panel',
        },
        {
          hidden: !hasPermissionInApp(user, APPS_IDS.SCORECARDS, [
            USER_PERMISSION_KEYS.CAN_VIEW_SQM,
          ]),
          id: 'WM_HOME.HEADER.SCORECARDS',
          title: 'WM_HOME.HEADER.SCORECARDS',
          path: '/sqm/list',
        },
      ],
    },
    {
      id: 'WM_HOME.HEADER.USER',
      title: 'WM_HOME.HEADER.USER',
      nav: [
        {
          id: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
          title: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
          path: '/user/settings',
        },
        {
          id: 'WM_HOME.HEADER.LOGOUT',
          title: 'WM_HOME.HEADER.LOGOUT',
          path: `${environment.authAppUrl}/logout`,
        },
      ],
    },
  ],
  clientCreation: (client: Client, user: User): NavigationSection => {
    const clientId = (client && client.id) || NEW_CLIENT_ROUTE;
    const marketsDisabled = !client?.markets?.length;

    return [
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.NEW_ADVERTISER',
        title: 'WM_HOME.NAVIGATION_CLIENTS.NEW_ADVERTISER',
        path: `/clients/client-creation/new`,
        hidden: client?.isAccepted || clientId !== NEW_CLIENT_ROUTE,
      },
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.PENDING_REQUEST',
        title: 'WM_HOME.NAVIGATION_CLIENTS.PENDING_REQUEST',
        path: `/clients/client-creation/${clientId}/pending`,
        hidden: client?.isAccepted || clientId === NEW_CLIENT_ROUTE,
      },
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.ADVERTISER',
        title: 'WM_HOME.NAVIGATION_CLIENTS.ADVERTISER',
        path: `/clients/client-creation/${clientId}/client-management`,
        disabled: !checkAccessToClientEdit(user),
        hidden: !client?.isAccepted,
      },
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.MARKETS',
        title: 'WM_HOME.NAVIGATION_CLIENTS.MARKETS',
        path: `/clients/client-creation/${clientId}/markets-management`,
        hidden: !client?.isAccepted || marketsDisabled,
      },
    ];
  },
  userSection: () => [
    {
      name: UserSectionNavigationName.news,
      hidden: false,
      disabled: false,
    },
    {
      name: UserSectionNavigationName.help,
      hidden: true,
      disabled: false,
      children: [
        {
          name: UserSectionNavigationName.helpTrainingPortal,
          hidden: false,
          disabled: false,
        },
        {
          name: UserSectionNavigationName.helpProductTour,
          hidden: false,
          disabled: false,
        },
        {
          name: UserSectionNavigationName.helpSitemap,
          hidden: false,
          disabled: false,
        },
        {
          name: UserSectionNavigationName.helpContextualHelp,
          hidden: false,
          disabled: false,
        },
      ],
    },
  ],
  userSettings: (): NavigationSection => [
    {
      id: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
      title: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
      path: '/user/settings',
    },
  ],
};
