export const environment = {
  appName: 'WMOS',
  environmentName: 'staging',
  production: true,
  mode: window['box-env']['mode'],
  isWMAgency: window['box-env']['isWMAgency'],
  excludeDevelopersFromSegment: false,

  // assets
  appsAssetsPath: '/_apps/apps/',

  // API urls
  appsDataApiUrl: '/_apps/apps/api',
  authAppUrl: '/_apps/auth/api',
  clientDataApiUrl: '/_apps/clients/api',
  contextualHelpApi: '/_apps/search/api',
  projectBuilderApi: '/_apps/growth-path/api/v1',
  newsApiUrl: '/_apps/news-api/api',
  profilePicturesApiUrl: '/_apps/profile-pictures/api',
  youApi: '_apps/you/api',
  trackerApi: '/_apps/traffic-tracker',

  // microFE urls
  audienceTranslatorAppUrl: 'https://int-audxlator.choreograph.com/xlator/main.js',
  appsAppUrl: '/_apps/apps/main.js',
  audienceGalaxyAppUrl: '/_apps/audience-galaxy/main.js',
  osPayAppUrl: '/_apps/os-pay/main.js',
  mediaMarketingMixAppUrl: '/_apps/media-marketing-mix/main.js',
  productsStatsAppUrl: '/_apps/products-stats/main.js',
  geographAppUrl: '/_apps/geograph/main.js',
  audiencesAppUrl: '/_apps/audiences-ui/main.js',
  provokeBriefAppUrl: '/_apps/provoke-brief/main.js',
  velocityAppUrl: '/_apps/velocity/main.js',
  cxiAudiencesAppUrl: '/_apps/cxi-audiences/main.js',
  growthPathAppUrl: '/_apps/growth-path/main.js',
  sitemapAppUrl: '/_apps/sitemap/main.js',
  budgetAppUrl: '/_apps/budget/main.js',
  campaignsUiAppUrl: '/_apps/campaigns-ui/main.js',
  campaignsSetupAppUrl: '/_apps/campaigns-setup/main.js',
  expressScenariosAppUrl: '/_apps/architect-junior/main.js',
  cartAppUrl: '/_apps/cart/main.js',
  documentationAppUrl: '/_apps/mxmz-documentation/main.js',
  retailCompassAppUrl: '/_apps/retail-compass/main.js',
  growthOpportunitiesAppUrl: '/_apps/growth-opportunities/main.js',
  loginAppUrl: '/_apps/login-box/main.js',
  marketAdminAppUrl: '/_apps/market-admin/main.js',
  mediaPlanComparisonAppUrl: '/_apps/mp-comparison/main.js',
  mediaPlanResultsAppUrl: '/_apps/mp-results/main.js',
  mediaPlanUploaderAppUrl: '/_apps/mp-uploader/main.js',
  newsSettingsAppUrl: '/_apps/news-settings/main.js',
  peopleAppUrl: '/_apps/people/main.js',
  profilePageAppUrl: '/_apps/profile-page/main.js',
  scorecardsAppUrl: '/_apps/scorecards/main.js',
  scorecardsQuestionsManagementAppUrl: '/_apps/sqm/main.js',
  touchpointsAppUrl: '/_apps/new-touchpoints/main.js',
  touchpointsUIAppUrl: '/_apps/touchpoints-ui/main.js',
  youPageAppUrl: '/_apps/you/main.js',
  fourMoresAppUrl: '/_apps/sog/main.js',
  dataStrategyDeckAppUrl: '/_apps/data-strategy-deck/main.js',
  consumerExperienceInsightsAppUrl: '/_apps/customer-experience-insights/main.js',
  influencerViewerAppUrl: '/_apps/influencer-viewer/main.js',
  aiCenterAppUrl: '/_apps/ai-center/main.js',

  // extra apps config
  matomoId: 23,
  sentryDSN: 'https://1e3070acc5e8431a97458440b6330b46@sentry.hygienetools.com/6',
  wppOpenApiUrl: 'https://facade-api-ch-stage.os-dev.io',
  wppOpenTenantPrefix: 'wm',
  sentryEnvironment: 'wmos-staging',
  sentryRelease: 'c2b12b483d85375609ecdf98464495c417b70525',

  appsIds: {
    appId: '2141e53d-5d3e-4bcc-9803-0c6ee3a2beee',
    cartAppId: '8a87c4d8-c557-4cc4-ad11-9c16bfb4c73e',
    clientManagementAppId: 'f36c1e7f-460a-4e98-a2f9-bc1a1698e664',
    audienceGalaxyId: 'ea558f5e-904d-429c-a938-543b166626e0',
    osPayId: '3bed44af-46d7-4890-b1ca-972d0053e5f1',
    mediaMarketingMixAppId: '052e06eb-ec7c-4637-8b66-5d4b0bdb5d26',
    touchpointsAppId: '4a67f29b-11bd-4048-9730-8356a0c1e3ef',
    touchpointsUIAppId: 'fddd331f-0655-492c-b244-ff58438d6654',
    scorecardsAppId: 'a9b3fcec-7b4a-4ecb-86b8-74260e90284c',
    audiencesAppId: '0c15ebe3-7194-40d7-a171-a2315d4b94ac',
    appsAppId: '72f53f85-ab52-4418-8fa9-22284d3144a6',
    dataSourcesAppId: 'def6f628-b30c-4277-9907-fe1da04413f9',
    retailCompassAppId: '5ba63e3f-0c99-4df1-9b26-6bc22d097d0b',
    campaignsAppId: '74632b20-8e79-4d73-b6c7-d1468bbdc993',
    documentationProxyAppId: '81502a29-c718-4dc0-ada3-ddbdbb4c5485',
    budgetAppId: '3cec5e49-dc06-42d6-b5bc-4e97b9231894',
    marketAdminAppId: '5c309c6b-8284-4545-9142-4a461c13df8a',
    growthOpportunitiesAppId: '387779dd-b746-47c6-a593-9f99b493a055',
    newsSettingsAppId: '0d795a4d-2804-4917-a90f-5c1149b9bddc',
    youPageAppId: '8218a400-dc70-4b72-ac2d-b13f7b872be7',
    provokeBriefAppId: 'f36886f6-c86d-4950-8144-00d485c20d6e',
    velocityAppId: '31d35015-4cc8-4e13-9693-3bffd82c79e3',
    cxiAudiencesAppId: '8c940baa-ed8d-4a48-b1c7-ccc1c1b306b0',
    contextualHelpAppId: '121b87ea-c541-492f-8701-4a3e20498892',
    fourMoresAppId: '40bd521c-7a96-426d-a592-3841943a495e',
    audienceTranslatorAppId: 'fee2d1b9-f8bd-4426-bab6-21cf6fc53465',
    mediaPlanComparisonAppid: '',
    dataStrategyDeckAppId: 'cc189de1-fd4f-4c05-8d5c-a23b6d547591',
    growthPathAppId: '9973ae2f-fe97-4286-a0ea-f3fec86c2507',
    sitemapAppId: 'ff371baa-0c8c-438d-a6e6-cb3323a15878',
    productsStatsAppId: '7c41b5a6-a69c-42d1-8bdc-b2e0ca9e9ab9',
    geographAppId: 'fbdd7767-51fc-4cc1-ae1d-19e3da9676c2',
    consumerExperienceInsightsAppId: '0b89c081-76f0-4b2d-b4ab-22e2be5eaec9',
    influencerViewerAppId: '7a2f428c-af7f-4591-95ac-45e9fd165a8b',
    expressScenariosAppId: 'da47acfa-2e7f-4273-a8c9-c1ee976d1885',
    superSearchAppId: '0354353d-7a23-48ac-abc5-33c086934862',
    aiCenterAppId: '245c6f1d-b646-4c7a-b3fa-aa337d6402e7',
  },
  wikiDomain: 'https://help.wmos.hygienetools.com',
  questionnaireUrl: 'https://forms.office.com/r/xHxAcPnHXf',
  segmentAnalyticsKey: 'SBuNIHS3918VrxirP45JcZCWvUfZYBFW',
  growthPathSurveyUrl: 'https://app.useberry.com/t/D01gD5pb/',
  defaultClient: '2bc7433e-f36b-1410-8507-00652db6c478',
  superSearchUrl: 'https://wmos.hygienetools.com/_apps/ai-chats/',
  formsUrl:
    'https://forms.office.com/Pages/ResponsePage.aspx?id=u-HLhj8hcUKxdL1Z0DyHo8sb9G_KBmRPoUSz1YVXZFdUNVVHTUZCWlpQUlgyNFMyVTlTUERMS1RYVC4u',
};
