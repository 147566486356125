export const environment = {
  environmentName: 'production',
  mode: window['box-env']['mode'],
  isWMAgency: window['box-env']['isWMAgency'],
  excludeDevelopersFromSegment: true,

  audienceTranslatorAppUrl: 'https://audxlator.choreograph.com/xlator/main.js',
  mediaPlanComparisonAppUrl: '/_apps/mp-comparison/main.js',
  mediaPlanResultsAppUrl: '/_apps/mp-results/main.js',
  mediaPlanUploaderAppUrl: '/_apps/mp-uploader/main.js',
  loginAppUrl: '/_apps/login-box/main.js',
  clientDataApiUrl: '/_apps/clients/api',
  audiencesAppUrl: '/_apps/audiences-ui/main.js',
  appsAppUrl: '/_apps/apps/main.js',
  appsDataApiUrl: '/_apps/apps/api',
  appsAssetsPath: '_apps/apps/',
  retailCompassAppUrl: '/_apps/retail-compass/main.js',
  youPageAppUrl: '/_apps/you/main.js',
  audienceGalaxyAppUrl: '/_apps/audience-galaxy/main.js',
  osPayAppUrl: '/_apps/os-pay/main.js',
  mediaMarketingMixAppUrl: '/_apps/media-marketing-mix/main.js',
  productsStatsAppUrl: '/_apps/products-stats/main.js',
  geographAppUrl: '/_apps/geograph/main.js',
  touchpointsAppUrl: '/_apps/new-touchpoints/main.js',
  touchpointsUIAppUrl: '/_apps/touchpoints-ui/main.js',
  budgetAppUrl: '/_apps/budget/main.js',
  campaignsUiAppUrl: '/_apps/campaigns-ui/main.js',
  campaignsSetupAppUrl: '/_apps/campaigns-setup/main.js',
  expressScenariosAppUrl: '/_apps/architect-junior/main.js',
  documentationAppUrl: '/_apps/mxmz-documentation/main.js',
  growthOpportunitiesAppUrl: '/_apps/growth-opportunities/main.js',
  marketAdminAppUrl: '/_apps/market-admin/main.js',
  authAppUrl: '/_apps/auth/api',
  cartAppUrl: '/_apps/cart/main.js',
  fourMoresAppUrl: '/_apps/sog/main.js',
  dataStrategyDeckAppUrl: '/_apps/data-strategy-deck/main.js',
  consumerExperienceInsightsAppUrl: '/_apps/customer-experience-insights/main.js',
  influencerViewerAppUrl: '/_apps/influencer-viewer/main.js',
  aiCenterAppUrl: '/_apps/ai-center/main.js',
  appName: 'WMOS',
  matomoId: 25,
  scorecardsAppUrl: '/_apps/scorecards/main.js',
  provokeBriefAppUrl: '/_apps/provoke-brief/main.js',
  velocityAppUrl: '/_apps/velocity/main.js',
  cxiAudiencesAppUrl: '/_apps/cxi-audiences/main.js',
  growthPathAppUrl: '/_apps/growth-path/main.js',
  sitemapAppUrl: '/_apps/sitemap/main.js',
  production: true,
  newsSettingsAppUrl: '/_apps/news-settings/main.js',
  profilePicturesApiUrl: '/_apps/profile-pictures/api',
  profilePageAppUrl: '/_apps/profile-page/main.js',
  peopleAppUrl: '/_apps/people/main.js',
  newsApiUrl: '/_apps/news-api/api',
  youApi: '_apps/you/api',
  contextualHelpApi: '_apps/search/api',
  projectBuilderApi: '_apps/growth-path/api/v1',
  scorecardsQuestionsManagementAppUrl: '/_apps/sqm/main.js',
  sentryDSN: 'https://1e3070acc5e8431a97458440b6330b46@sentry.hygienetools.com/6',
  wppOpenApiUrl: 'https://facade-api-prd-one.os.wpp.com',
  wppOpenTenantPrefix: 'wavemaker',
  sentryEnvironment: 'wmos-master',
  sentryRelease: 'c2b12b483d85375609ecdf98464495c417b70525',
  appsIds: {
    appId: 'be6c4d65-c38e-4981-83ad-ceb9e0116a00',
    scorecardsAppId: '701cbb16-8721-47ca-a979-ffc4763f3c59',
    cartAppId: '1dece7d4-8966-4ba0-bd05-54296f8e5e6a',
    clientManagementAppId: 'a845117d-1559-4d1b-8efd-79240382090b',
    audienceGalaxyId: '92e1992d-9aff-4947-afb9-330c47f086c6',
    osPayId: '889ff051-ad4d-410d-8a0c-1348acea358e',
    mediaMarketingMixAppId: '496ff61b-1f67-4158-8f8d-ca5d89fcc7ad',
    touchpointsAppId: '7f5d6fcb-6884-4867-8e7c-700e87d97178',
    touchpointsUIAppId: '5d3959b3-8caf-4d85-820d-45c89cea988c',
    campaignsAppId: '9d32a3d4-64b0-418c-9770-23ba2a639cd4',
    audiencesAppId: 'b996f267-0372-4299-a53d-4ad7aefdd6cb',
    dataSourcesAppId: 'ad5493d3-ecef-4db1-921f-648b2542d7ee',
    appsAppId: 'c9f7b51d-0cea-444c-928e-fc89d55df4fa',
    retailCompassAppId: 'cc9bf1aa-1827-44bf-abbf-fb1f94e453fc',
    documentationProxyAppId: 'fb2ca0b0-3471-448a-a41b-a8f7651682d2',
    budgetAppId: '24ab5914-1d13-4119-bc79-14968e9967e1',
    marketAdminAppId: '356c6032-f0a5-4426-b5ee-0708c50d7217',
    growthOpportunitiesAppId: 'dcfb851c-436c-445e-a53b-61f040c2aca8',
    newsSettingsAppId: '91d9ce3c-56da-4a36-9ed2-9c152e88d4ce',
    youPageAppId: '8218a400-dc70-4b72-ac2d-b13f7b872be7',
    provokeBriefAppId: '30c38168-91b3-4c88-b60d-23ef4ac6c667',
    velocityAppId: '8ecde5a0-c8a2-4d86-883d-e281204bbcdc',
    cxiAudiencesAppId: 'fbc4c73b-f009-4d38-a80a-4f974562a220',
    contextualHelpAppId: '21af3ee8-4725-4d9b-af3b-235713c18581',
    fourMoresAppId: '1efea35c-6097-4895-8892-33467a1af682',
    audienceTranslatorAppId: 'ef224ea6-cd89-4776-81b7-ad375b31032b',
    mediaPlanComparisonAppid: '',
    dataStrategyDeckAppId: '679d25bd-b365-45e4-b6b0-267276bccad9',
    growthPathAppId: 'c269d823-9881-4be1-9e36-0fd1fd56e418',
    sitemapAppId: 'db2c925a-62b7-452c-9d6b-e22f90b8af7d',
    productsStatsAppId: 'd208217b-e3a9-405b-b2ff-a4ce05b9c99c',
    geographAppId: '79073f6d-340b-4da8-986e-3aebcd45bc5f',
    consumerExperienceInsightsAppId: '7f24464b-3563-4d0c-830b-0c102f54d6e1',
    influencerViewerAppId: 'f8823972-ca25-498f-92a3-5223726e00a4',
    expressScenariosAppId: '47f814b1-05da-4719-b56d-007edc480442',
    superSearchAppId: 'b5819526-02de-46d4-8402-ed54bffacf67',
    aiCenterAppId: '1bdf9f8e-f605-4a43-883f-5c473f394359',
  },
  wikiDomain: 'https://help.os.wmglobal.com',
  questionnaireUrl: 'https://forms.office.com/r/xHxAcPnHXf',
  trackerApi: '/_apps/traffic-tracker',
  segmentAnalyticsKey: 'X32KCFuMo02A0LwcxLDCCwXLFksOw5qJ',
  growthPathSurveyUrl: 'https://app.useberry.com/t/5968Z923/',
  defaultClient: 'dce3463e-f36b-1410-82da-0014a329a461',
  superSearchUrl: 'https://os.wmglobal.com/_apps/ai-chats/',
  formsUrl:
    'https://forms.office.com/Pages/ResponsePage.aspx?id=u-HLhj8hcUKxdL1Z0DyHo8sb9G_KBmRPoUSz1YVXZFdUNVVHTUZCWlpQUlgyNFMyVTlTUERMS1RYVC4u',
};
